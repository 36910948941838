<template>
  <div class="home">
    <!-- <img alt="Vue logo" src="../assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/> -->
   



    <!-- title -->
    <titles></titles>
    <!-- side -->
    <broadside></broadside>
    <!-- banner -->
    <div class="banner">
      <img src="@/assets/bannerHome.png" />
    </div>
    <div class="index_serve_box">
      <div class="index_serve_nav_box">
        <!-- 如果需要使用分页器 -->
        <div class="swiper-pagination"></div>
      </div>
      <div class="swiper_box">
        <div class="all_box">
          <div class="swiper-container" style="width: 100%; height: 520px">
            <div class="swiper-wrapper">
              <div class="swiper-slide" v-for="item in swiperList">
                <div class="swiper_item_box">
                  <div class="swiper_item_left">
                    <img :src="item.img" />
                    <div class="swiper_left_text">
                      <p>{{ currentYear }}年耕耘，邦芒已成为国内领先的</p>
                      <p>服务外包领创品牌</p>
                    </div>
                  </div>
                  <div class="swiper_item_right">
                    <div class="swiper_ljzx">立即咨询</div>
                    <div class="swiper_text">
                      {{ item.text }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 如果需要导航按钮 -->

          <div class="swiper-button-prev"></div>
          <div class="swiper-button-next"></div>
        </div>
      </div>
    </div>

    <!-- About -->
    <div class="about">
      <div class="all_box">
        <div class="about_box">
          <div class="about_left">
            <h3>ABOUT<br />Bangmang</h3>
            <h4>关于邦芒</h4>
            <div class="henxian"></div>
            <p>坚持专注于企业与人才服务</p>
          </div>
          <div class="about_right">
            <aboutText></aboutText>
          </div>
        </div>
      </div>
    </div>
    <!-- friend -->
    <div class="friend_box">
      <img src="@/assets/friend.png" />
    </div>

    <!-- check -->

    <check :type="2" @gotoContact="gotoContact($event)"></check>
    <foots></foots>
  </div>
</template>

<script>
//导入图片
import serveImg1 from "@/assets/serve01.png";
import serveImg2 from "@/assets/serve02.png";
import serveImg3 from "@/assets/serve03.png";
import serveImg4 from "@/assets/serve04.png";
import serveImg5 from "@/assets/serve05.png";
import serveImg6 from "@/assets/serve06.png";


import broadside from "./broadside.vue"
import Swiper from "swiper";
import check from "@/components/check.vue"
import aboutText from "@/components/aboutText.vue";
import titles from "@/components/titles.vue";
import foots from "@/components/foots.vue";


export default {
  name: "Home",
  components: {
    titles,
    foots,
    aboutText,
    check,
    broadside,
  },
  data() {
    return {
      currentYear: new Date().getFullYear() - 2007,
      broadsideDetailVisible: false,
      swiperList: [
        {
          title: "人事服务外包HRO",
          img: serveImg6,
          text: "邦芒人事代理HRO，根据企业的实际需求，帮助企业简化并整合人力资源管理流程，降低企业人力资源管理成本、优化企业用工风险、实现管理效率最大化。",
        },
        {
          title: "业务流程外包BPO",
          img: serveImg5,
          text: "邦芒业务流程外包BPO, 通过专业化的人员管理模式和规范系统化的操作流程，承接企业分离出的重复性和非核心岗位，帮助企业简化用工程序，降低人员管理成本，提升核心竞争力。",
        },
        {
          title: "招聘服务外包RPO",
          img: serveImg4,
          text: "邦芒招聘服务外包RPO, 利用自身丰富的行业人才资源、专业的人才评价工具和标准的管理流程优势，帮助企业完成部分或全部员工招聘工作，提升企业招聘质量、优化招聘周期、改善整体行政流程。",
        },
        {
          title: "劳务派遣",
          img: serveImg3,
          text: '邦芒作为具备劳务派遣经营资质的机构，与劳动者订立劳动合同 ，把劳动者派往用工企业，再由用工企业向邦芒支付服务费用的用工形式。邦芒劳务派遣服务能够有效帮助企业减少劳动争议，降低用人风险，自主灵活用工，专注核心业务发展，提升核心竞争力，真正实现 "省心"、"省力"、"省钱"。',
        },
        {
          title: "短期用工",
          img: serveImg2,
          text: "邦芒为企业的中短期空缺岗位提供招聘、管理、绩效考核、退回人员替换等服务，助力企业实现更合理有效的人力资源配置。",
        },
        {
          title: "HR SaaS",
          img: serveImg1,
          text: "邦芒提供的技术支持产品包括：HROSaaS系统、OA系统、ERP系统、发薪系统、工资条系统、用工体检系统、个税申报系统等。产品旨在连接企业业务端和个人端，切实提高企业的运营效率，让企业运作更具信息化、数字化、智能化。",
        },
      ],

    };
  },

  mounted() {

    this.initSwiper();
  },
  methods: {
    //跳转到contact 并查询
    gotoContact(e) {
      this.$router.push({
        path: '/contact',
        query: {
          sCode: e.sCode,
          qCode: e.qCode,
          compname: e.compname
        }
      })
    },
    initSwiper() {
      this.$nextTick(() => {
        var mySwiper = new Swiper(".swiper-container", {
          loop: true,

          // 如果需要分页器
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
            renderBullet: function (index, className) {
              let titleName;
              if (index == 0) {
                titleName = "人事服务外包HRO";
              }
              if (index == 1) {
                titleName = "业务流程外包BPO ";
              }
              if (index == 2) {
                titleName = "招聘服务外包RPO";
              }
              if (index == 3) {
                titleName = "劳务派遣";
              }
              if (index == 4) {
                titleName = "短期用工";
              }
              if (index == 5) {
                titleName = "HR SaaS";
              }
              return '<span class="' + className + '">' + titleName + "</span>";
            },
          },

          // 如果需要前进后退按钮
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
        });
        console.log(mySwiper);
      });
    },

  },
};
</script>


<style lang="scss">
.index_serve_nav_box {
  .swiper-pagination {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    top: 0;
    height: 75px;
    line-height: 75px;
  }

  .swiper-pagination-bullet {
    width: auto;
    height: auto;
    background: none;
    margin: 0 48px;
    font-size: 16px;
    color: #696969;
    opacity: 1;
  }

  .swiper-pagination-bullet-active {
    font-weight: bold;
    position: relative;
  }

  .swiper-pagination-bullet-active:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 3px;
    background: #d70035;

  }
}

.swiper_box {
  .swiper-button-prev:after {
    content: "";
    width: 26px;
    height: 19px;
    background: url(../assets/serve_left.png) center center no-repeat;
  }

  .swiper-button-next:after {
    content: "";
    width: 56px;
    height: 18px;
    background: url(../assets/serve_right.png) center center no-repeat;
  }

  .swiper-button-prev {
    left: auto;
    right: 60px;
    width: 26px;
    top: 80%;
  }

  .swiper-button-next {
    left: auto;
    right: -40px;
    width: 56px;
    top: 80%;
  }
}

.banner {
  width: 100%;

  img {
    width: 100%;
    display: block;
  }
}

.message_box {}

body {
  margin: 0;
}

.all_box {
  width: 1300px;
  margin: 0 auto;
  position: relative;
}

.check_select {
  .el-input__wrapper {
    background: rgba(255, 255, 255, 0.3);
    height: 50px;
    box-shadow: none;
    padding: 0 20px;
  }
}
</style>

<style lang="scss" scoped>
.about {
  padding: 130px 0 115px;
}

.swiper_ljzx {
  display: inline-block;
  text-align: center;
  width: 188px;
  height: 46px;
  border: 2px solid #d70035;
  line-height: 46px;
  color: #d70035;
}

.swiper_text {
  text-align: left;
  font-size: 16px;
  color: #656565;
  line-height: 36px;
  text-indent: 2em;
  margin-top: 70px;
}

.swiper_left_text {
  text-align: left;
  font-size: 18px;
  color: #959595;
  padding-left: 55px;
  margin-top: 32px;

  p {
    margin: 0;
    margin: 5px 0;
  }
}

.swiper_item_box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background: #fff;
  height: 100%;
}

.swiper_item_right {
  box-sizing: border-box;
  padding-right: 60px;
  padding-top: 60px;
  width: 30%;
  text-align: right;
}

.swiper_box {
  padding-bottom: 60px;
  padding-top: 45px;
  background: #f2f2f2;
  position: relative;
}

.index_serve_nav_box {
  position: relative;
  width: 100%;
  height: 75px;
  border-bottom: 3px solid #e0e0e0;
}



.about_left {
  text-align: left;

  h3 {
    font-size: 52px;
    color: #5e5e5e;
    font-weight: bold;
    opacity: 0.28;
    margin: 0;
    margin-bottom: 35px;
    text-transform: uppercase;
  }

  .henxian {
    width: 127px;
    height: 3px;
    background: #c5a97b;
    margin-bottom: 30px;
  }

  h4 {
    font-size: 55px;
    color: #5e5e5e;
    font-weight: bold;
    margin: 0;
    margin-bottom: 70px;
  }

  p {
    font-size: 18px;
    color: #666666;
    margin: 0;
  }
}

.about_box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .about_right {
    width: 70%;
  }
}

.friend_box {
  width: 100%;

  img {
    width: 100%;
  }
}
</style>