<!-- 通用about -->
<template>
  <div class="aboutText">
    <div class="about_text_box">
      <p>
        邦芒服务外包有限公司创建于2007年，业务涵盖服务外包、人事代理、人力资源SaaS服务、企业福利平台、产业园区运营、招商引资、财税代理、产业投资等多航道业务，并积极探索IT研发、互联网直聘平台等创新领域，以业务协作、资源交互的协同增效模式，打造一站式全产业链集团型企业。
      </p>
      <p>
        {{ currentYear }}载耕耘，邦芒已实现全国核心市场布局，同时陆续在中国香港和美国等地设立公司，目前全球直营分公司已有228家，服务雇员超100万人。
      </p>
      <p>
        未来，邦芒将继续坚持以技术为驱动，以客户为中心，依托独特的多产业事业集群服务平台和服务全球的骨干网络和全域人才数据库，提供全业态、全周期、数字化的“人力资源一站式解决方案”。“大智兴邦，大品传芒”，邦芒各事业部高效协同，聚力共赢，为推进稳就业、保民生不断努力！
      </p>
    </div>
    <div class="about_number_list">
      <div class="about_number_item">
        <h4>228</h4>
        <p>全国设立228家直营公司</p>
      </div>
      <div class="about_number_item">
        <h4>400</h4>
        <p>业务辐射全国400+城市</p>
      </div>
      <div class="about_number_item">
        <h4>1000000+</h4>
        <p>累计为100多万雇员提供优质服务</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      currentYear: new Date().getFullYear() - 2007,
    }
  }
}
</script>
<style lang="scss" scoped>

.about_text_box {
  font-size: 16px;
  color: #696969;
  line-height: 24px;
  text-align: left;
  text-indent: 2em;
  p {
    margin: 25px 0;
  }
}
.about_number_list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  .about_number_item:first-child {
    margin-left: 0;
  }
  .about_number_item {
    margin-left: 140px;
    text-align: left;
    h4 {
      font-size: 24px;
      margin: 0;
      color: #b89369;
      margin-bottom: 12px;
    }
    p {
      font-size: 14px;
      margin: 0;
      color: #3f3a39;
    }
  }
}

</style>